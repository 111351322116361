import { useState, useEffect } from "react";
import { getDonorsPage } from "./../cms/utilities";
import { Link } from "react-router-dom";
import Header from "../components/header";
import PageSection from "../components/pageContent";
import heroBg from "../images/donate/donate_hero_bg.jpg";
import rightArrow from "../images/icons/right-arrow.svg";
import wrapupBg from "../images/donors/donors_wrapup-bg.jpg";
import mobileWrapup from "../images/donors/donors_wrapup-mobile.jpg";
import halfwayMessageBg from "../images/donors/donors-halfway-message.jpg";

//@ts-ignore
import Fade from "react-reveal/Fade";

interface ProcessedSection {
	letter: string;
	names: string[];
}

const Donors = () => {
	let isDesktop = window.innerWidth >= 1024;
	const [processedContent, setProcessedContent] = useState<ProcessedSection[]>([]);
	const [numTopHalfLetters, setNumTopHalfLetters] = useState<number>(0);

	useEffect(() => {
		const fetchDonors = async () => {
			const donorsPage = await getDonorsPage();
			const content = donorsPage.content.rendered;

			// Create a temporary div to parse the HTML
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = content;

			// Process the content
			const sections: ProcessedSection[] = [];
			let currentSection: ProcessedSection | null = null;

			// Go through each element
			tempDiv.childNodes.forEach((node) => {
				if (node.nodeName === 'H1') {
					if (currentSection) {
						sections.push(currentSection);
					}
					currentSection = {
						letter: node.textContent || '',
						names: []
					};
				} else if (node.nodeName === 'P' && currentSection) {
					currentSection.names.push(node.textContent || '');
				}
			});
			
			// Add the last section
			if (currentSection) {
				sections.push(currentSection);
			}

			setProcessedContent(sections);
			setNumTopHalfLetters(Math.ceil(sections.length / 2));
		};
		fetchDonors();
	}, []);

	const generateLettersMarkup = () => {
		let lettersDone = 0;
		let lettersMarkup = <></>;

		processedContent.forEach((section, index) => {
			if (lettersDone === numTopHalfLetters) {
				lettersMarkup = (
					<>
						{lettersMarkup}
						<div className="halfway_message">
							<div
								className="message-bg"
								style={{ backgroundImage: `url(${halfwayMessageBg})` }}
							>
								<Fade bottom>
									<h1 className="message">Every Knight. Every Day.</h1>
								</Fade>
							</div>
						</div>
						<div className="letter-section" key={`${index}`}>
							<Fade bottom>
								<h1 className="letter-heading">{section.letter}</h1>
								<div className="name-list">
									{section.names.map((name, nameIndex) => (
										<p className="donor-name" key={`${nameIndex}`}>
											{name}
										</p>
									))}
								</div>
							</Fade>
						</div>
					</>
				);
			} else {
				lettersMarkup = (
					<>
						{lettersMarkup}
						<div className="letter-section" key={`${index}`}>
							<Fade bottom>
								<h1 className="letter-heading">{section.letter}</h1>
								<div className="name-list">
									{section.names.map((name, nameIndex) => (
										<p className="donor-name" key={`${nameIndex}`}>
											{name}
										</p>
									))}
								</div>
							</Fade>
						</div>
					</>
				);
			}
			lettersDone++;
		});

		return lettersMarkup;
	};

	return (
		<div className="container donors">
			{!isDesktop && <Header logoColor="blue" />}
			<PageSection className="donors-section_intro">
				<div
					className="hero-bg"
					style={isDesktop ? { backgroundImage: `url(${heroBg})` } : {}}
				>
					{isDesktop && <Header logoColor="blue" />}
					<Fade bottom>
						<h1 className="heading">
							You Make Us <b>Better.</b>
						</h1>
						<p className="intro-text">
							{/* The donors listed below have made the Hoban experience better for all students,
							now and in the future. Their gifts will ensure Hoban can continue to provide a
							Holy Cross education to the next generation of leaders—regardless of their
							financial circumstances—with the competence to see and the courage to act.{" "}
							<strong>
								Are you ready to join them in leading Hoban confidently into the future?
							</strong> */}
							The following donors have answered the call and graciously contributed to Every Knight, Every Day. Their belief in the campaign pillars of Achievement, Community & Wellbeing and Access will help ensure the vibrancy of Hoban for generations to come.&nbsp;
							<strong>
								Are you ready to join them in leading Hoban confidently into the future?
							</strong>
						</p>
					</Fade>
				</div>
			</PageSection>
			<PageSection className="donors-section_names">
				{isDesktop && <p className="vertical-title">Donors</p>}
				{processedContent.length > 0 && generateLettersMarkup()}
			</PageSection>
			<PageSection className="donors-section_wrapup">
				<div
					className="wrapup-bg"
					style={
						isDesktop
							? { backgroundImage: `url(${wrapupBg})` }
							: { backgroundImage: `url(${mobileWrapup})` }
					}
				>
					<Fade bottom>
						<p className="title">True to you</p>
						<p>Join us in strengthening the Hoban experience.</p>
						<Fade bottom>
							<Link to="/donate" className="cta-button centered margin-bottom">
								<span>Donate Now</span>
								<img src={rightArrow} />
							</Link>
						</Fade>
					</Fade>
				</div>
			</PageSection>
		</div>
	);
};

export default Donors;
