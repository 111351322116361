import axios from "axios";
import {
  POSTS_API_URL,
  AUTHORS_API_URL,
  MEDIA_API_URL,
  DONORS_API_URL,
  DONOR_VOICES_API_URL,
  PAGES_API_URL,
} from "./constants";

export const getAllPostsFromServer = async () => {
  //   get all posts from Server
  try {
    const { data } = await axios.get(POSTS_API_URL);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getNumberPosts = async (numPosts: number) => {
  //   get all posts from Server
  try {
    const { data } = await axios.get(`${POSTS_API_URL}?per_page=${numPosts}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPostsForPage = async (
  numPostsPerPage: number,
  pageNumber: number
) => {
  //   get all posts from Server
  try {
    const { data } = await axios.get(
      `${POSTS_API_URL}?per_page=${numPostsPerPage}&page=${pageNumber}`
    );
    return data;
  } catch (error: any) {
    console.log(error);
    return error.response.data.code;
  }
};

export const getAuthor = async (id: any) => {
  try {
    const {
      data: { name },
    } = await axios.get(`${AUTHORS_API_URL}/${id}`);
    return name;
  } catch (error) {
    console.log(error);
  }
};

export const getFeaturedImage = async (id: any) => {
  try {
    const res = await axios.get(`${MEDIA_API_URL}/${id}`);
    return res.data.guid.rendered;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const getAllDonorVoices = async () => {
  try {
    const { data } = await axios.get(`${DONOR_VOICES_API_URL}?per_page=100`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllDonors = async () => {
  try {
    const { data } = await axios.get(DONORS_API_URL);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export interface Donor {
  firstName: string;
  lastName: string;
  fullName?: string;
}

// replacing this with comma separated list
export const getAllDonorsNames = async (): Promise<Donor[] | undefined> => {
  try {
    const { data } = await axios.get(`${DONORS_API_URL}?per_page=100`);
    let donors: Donor[] = [];
    data.map((donor: any) => {
      donors.push({
        firstName: donor.acf.first_name,
        lastName: donor.acf.last_name,
      });
    });
    return donors;
  } catch (error) {
    console.log(error);
  }
};

export const getHomePage = async () => {
  try {
    const { data } = await axios.get(`${PAGES_API_URL}?slug=home-page`);
    return data[0];
  } catch (error) {
    console.log(error);
  }
};

export const getDonorsPage = async () => {
  try {
    const { data } = await axios.get(`${PAGES_API_URL}?slug=donors-page`);
    //console.log('Full page content:', data[0]);
    return data[0];
  } catch (error) {
    console.log(error);
  }
};

export const getBlogPostPage = async () => {
  try {
    const { data } = await axios.get(`${PAGES_API_URL}?slug=blog-post-page`);
    return data[0];
  } catch (error) {
    console.log(error);
  }
};
